.tags {
  @extend .mb-0, .p-0, .list-unstyled, .d-flex, .justify-content-center;

  flex-wrap: wrap;

  > li,
  .tag {
    @extend .flex-fill, .mx-2, .mb-3;

    font-size: $h5-font-size;
    display: inline;

    > a {
      //@extend .badge, .badge-pill, .badge-secondary, .px-4, .mr-2;
      @extend .btn, .px-4, .w-100;

      &.tag-link {
        @extend .btn-outline-primary;
      }

      &.tag-browse {
        @extend .btn-primary;
      }

      padding-top: 8px;
      padding-bottom: 8px;
      //white-space: nowrap;

      > strong {
        @extend .pl-1, .font-weight-bold;
      }
    }
  }
}