.update {
  width: 100%;
  max-width: 900px;

  .updateContainer {
    border: 1px solid #808080bd;
    border-radius: 1%;
  }

  .wrapper-header {
    border-bottom: 1px solid #808080bd;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
  }

  .header_date span {
    font-weight: 700;
    font-size: 18px;
  }

  .header_edit span {
    font-size: 16px;
    font-weight: 400;
    color: #17a2b8;
    cursor: pointer;
  }

  .title-container {
    padding: 30px 30px;
    border-bottom: 1px solid #808080bd;
  }

  .title-wrapper:first-child {
    border-bottom: 1px solid #8080803d;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }

  .title-wrapper div {
    width: 33%;
    display: flex;
    justify-content: center;
  }

  .title-wrapper:first-child span {
    font-weight: 500;
    font-size: 18px;
  }

  .title-wrapper:last-child span {
    font-weight: 500;
    font-size: 16px;
  }

  .wrapper-body p {
    margin: 0px;
    font-size: 17px;
    font-weight: 400;
  }

  .body-container {
    padding: 20px;
  }

  .body-container div {
    padding-bottom: 30px
  }

  .body-container div p:first-child {
    padding-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
  }
}
