.input-group--newsletter {
  input {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
    border-radius: 0.3rem !important;
  }

  .send-button {
    right: 0;
    z-index: 3;
  }
}
