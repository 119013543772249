.footer {
  background: $green-x-dark;
  padding-top: 4.5rem;
  .footer__main {
    ul {
      list-style: none;
      padding-left: 0;
      li {
        & > * {
          color: $white;
          font-size: 14px;
          margin: 0;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  .footer__socket {
    p {
      color: $white;
      font-size: 14px;
      margin: 0;
    }
  }
}