.section__magazine {

  .container {
    min-height: 300px;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      @include py(2);

      .background-image {
        display: none!important;
      }
    }
  }
}
