.header__logo {
  @extend .flex-row, .align-items-center, .py-0, .py-lg-4, .text-primary;

  > svg {
    fill: var(--text-color);

    //.cls-1,
    .ssfs-logo {
      fill: theme-color("primary");
    }
  }

  .text__logo {
    @extend .text-primary, .float-right, .pl-1, .font-weight-bold;

    line-height: 1.15em;

    small {
      @extend .d-block, .text-center;
    }
  }

  &.AoA {
    > svg {
      width: 200px;
      height: auto;
    }
  }
}