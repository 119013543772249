// section-search
.section-search {
  margin-top: 9rem;

  @extend .container;
  @extend .position-relative;
  @extend .d-flex;
  @extend .flex-column;
  @extend .mb-3;

  // .section-search__title
  &__title {
    color: theme-color("black");
    //font-family: $font-family-base;
    font-size: 1.3125rem;
    //font-weight: $font-weight-medium;

    @extend .col-12;
    @extend .mb-2;
  }
}

.members {
  //margin-bottom: 9rem;

  //@extend .container;
  @extend .position-relative;
  @extend .d-flex;
  @extend .flex-wrap;
  @extend .row;

  // .content__head

  &__head {
    @extend .position-relative;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
    @extend .col-12;
    @extend .mb-6;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  // .content__result

  &__result {
    @extend .col-6;

    //font-family: $font-family-base;
    //font-weight: $font-weight-medium;

    //font-size: $font-size-lg;
    //line-height: $line-height-default;

    @include media-breakpoint-down(sm) {
      @include make-col(12);
      flex-direction: column;
    }
  }

  // .content__pagination

  &__pagination {
    @extend .col-6;

    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
  }

  .pagination {
    @extend .position-relative;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-end;
    // .pagination__button

    &__button {
      //font-family: $font-family-base;
      //font-weight: $font-weight-medium;
      color: theme-color("blue");

      text-decoration: underline;
      //font-size: $font-size-lg;
      //line-height: $line-height-default;

      &.no--active {
        display: none;
      }
    }

    // .pagination__this-page

    &__this-page {
      //font-family: $font-family-base;
      //font-weight: $font-weight-medium;
      @extend .mx-4;

      //font-size: $font-size-lg;
      //line-height: $line-height-default;
    }
  }
  // .content__wrap
  &__wrap {
    @extend .position-relative;
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .col-12;
  }
  // .content__card

  &__card-wrap {
    @extend .col-3;
    @extend .mb-4;

    @include make-col-ready();
    @include media-breakpoint-down(md) {
      @include make-col(4);
    }
    @include media-breakpoint-down(sm) {
      @include make-col(6);
    }
    @include media-breakpoint-down(xs) {
      @include make-col(12);
    }
  }
}
