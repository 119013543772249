.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  //padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

  &-sticky {
    position: -webkit-sticky;
    position: sticky;
  }

  &-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    //overflow-x: hidden;
    //overflow-y: auto;
  }

  .header-list {
    list-style: none;
    padding: 0;

    .header-item {
      padding: 0.875rem 0;

      .sidebar-header-logo {
        margin-left: 20px;
        height: 50px;

        svg {
          width: 150px;

          @include media-breakpoint-up(lg) {
            width: 200px;
          }
        }
      }
      
      .header-link {
        display: flex;
        font-weight: bolder;
        cursor: pointer;
        align-items: center;
        color: $secondary;
        font-weight: 600;
        text-decoration: none;
        
        .icon {
          width: 70px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          position: relative;
          
          img, svg {
            width: 26px;
            height: 26px;
          }
        }
        
        .text {
          display: flex;
          flex: 1 1 0%;
          align-items: center;
          
          span {
            -webkit-box-align: center;
            align-items: center;
            flex: 1 1 0%;
          }
          
          svg {
            height: 100%;
            margin-right: 15px;
            transform: rotate(0deg);
            transition: all 0.5s ease 0s;
          }
        }

        &:hover {
          &:not(.header-logo) {
            color: $green;
          }
        }
        
        &:after {
          display: none;
          position: absolute;
          right: 0;
        }
      }
      
      .header-logo {
        color: $body-color;
      }
      
      &.dropdown {
        &:hover {
          .text {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          margin-left: 0;
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, .1);
          a {
            color: $secondary;
            background-color: transparent;

            &:hover {
              color: $green;
            }
          }

          @include media-breakpoint-up(sm) {
            display: none;
            margin-top:0;
          }
        }
      }

      &:hover {
        .dropdown-menu{
          @include media-breakpoint-up(sm) {
            display: block;
          }
        }
      }
    }
  }
}