.posts {
  @extend .d-flex, .flex-wrap, .align-items-stretch, .row, .mb-4;

  //.postWrapper {
  //  @extend .col-12, .col-md-6, .mb-2;

    .post {
      @extend .d-flex, .flex-column, .h-100, .position-relative;

      border-bottom: 1px solid theme-color("muted");
      background: theme-color("lightgrey");

      &-category {
        @extend .position-absolute, .pl-1, .py-1, .pr-3, .bg-primary, .font-weight-bolder, .text-white;

        z-index: 1;
      }

      &-image {
        overflow: hidden;

        > div {
          padding-bottom: 25vh !important;
        }
      }

      &-content {
        @extend .p-1, .d-flex, .flex-column;

        .readingTime {
          @extend .mt-auto;
        }
      }

      &-footer {
       @extend .mt-auto, .p-1;
      }
    }
  //}
}
