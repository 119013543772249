.section__swiper {
  position: relative;
  overflow: hidden;

  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        font-size: 3rem;
        line-height: 3.75rem;
        font-weight: bold;
      }
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 7px;
      background: $grey-medium;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background: $success;
      }
    }
  }
}