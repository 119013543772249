.dialog__window {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 15px;
    margin-top: 15px;
}

.dialog__load {
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    transition: all .3s linear;
    opacity: 0;
    background: #4870df;
    color: #fff;
    font-size: 14px;
}

.dialog__load.active {
    opacity: 1;
}

.dialog__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.dialog__item-message {
    display: inline-block;
    margin-right: 15px;
    max-width: 45%;
    padding: 13px 20px;
    color: #000;
    background-color: #e8e5e5;
    border-radius: 5px;
    position: relative;
}

.dialog__item-date {
    font-size: 10px;
    color: #939393;
    position: absolute;
    line-height: normal;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 10px);
    white-space: nowrap;
}

.dialog__item-person{
    max-width: 100px;
}

.dialog__item-avatar {
    flex-shrink: 0;
}

.dialog__item--interlocutor {
    justify-content: flex-start;
}

.dialog__item--interlocutor .dialog__item-date {
    right: auto;
    left: calc(100% + 10px);
}

.dialog__item--interlocutor .dialog__item-message {
    color: #fff;
    background-color: #4870df;
    margin-left: 15px;
    order: 2;
}

.dialog-form {
    display: flex;
    margin-top: 10px;
    border-top: 1px solid #e8e5e5;
    padding: 15px;
}
