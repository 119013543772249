.cover-video-container{
    position: relative;
}

.cover-video{
    position: absolute;
    z-index: 1;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
}