.single {
  &-post {
    //.featured-image {
    //  @extend .mb-5;
    //
    //  height: 50vh;
    //  overflow: hidden;
    //
    //  .gatsby-image-wrapper > div {
    //    padding-bottom: 50vh !important;
    //  }
    //}

    //img {
    //  @extend .img-fluid;
    //}

    .post-content {
      line-height: $line-height-lg * 1.25;

      h1,
      h2,
      h3,
      h4,
      h5 {
        @extend .mb-2;

      }

      h1 {
        font-size: $h3-font-size;
      }

      h2 {
        font-size: $h3-font-size;
      }

      @include media-breakpoint-down(md) {
        h1 {
          font-size: $h4-font-size;
        }

        h4 {
          font-size: $h5-font-size;
        }

        h5 {
          font-size: $h5-font-size;
        }
      }

      img {
        @extend .img-fluid, .mb-3;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      p {
        overflow-wrap: break-word;
      }
    }

    .post-author {
      .gatsby-image-wrapper {
        > div {
          padding-bottom: 100% !important;
        }
      }
    }
  }
}