//  Table of Contents:
//
//   1. Global
//   2. Grid breakpoints
//   3. Base Typography
//   4. Typography Helpers
//   5. Abide
//   6. Accordion
//   7. Accordion Menu
//   8. ...
// 1. Global
// ---------

/**
  * Fonts
 */
$custom-file-font-family: "Poppins", sans-serif;
$font-family-base: "Poppins", sans-serif;

$display1-size:               5rem !default;
$display2-size:               4rem !default;
$display3-size:               3.5rem !default;
$display4-size:               3rem !default;

$blue-light: #40B4DC;
$blue-dark: #0c202f;
$black: #000;
$white: #fff;
$grey-x-light:#F6F7F9;
$grey-light:#d2d2d2;
$grey-medium:#B4B4B4;
$grey-dark:#5a5a5a;
$grey-form:#C8C8C8;

$green: #188268;
$green-dark: #185345;
$green-x-dark: #0F2B24;
$green-light: #BCD8D2;
$orange-light: #FFDA8E;
$yellow-light: #FFDA8E;
$red-light: #FFAAAA;
$red-x-light: #FFE6E6;

$border-green: #60998B;
$border-grey: #EDEDED;

$bg-grey: $grey-x-light;
$bg-green: $green;
$bg-dark: $green-dark;
$bg-green-x-dark: $green-x-dark;
$bg-green-light: $green-light;
$bg-red-x-light: $red-x-light;

$btn-success: #85B12B;

// Spacing: Control the default styling of most Bootstrap elements

$navbar-padding-x : 0rem !default;
$navbar-padding-y : 0rem !default;

$btn-border-radius: 0 !default;
$btn-border-radius-sm: 0 !default;
$btn-border-radius-md: 0 !default;
$btn-border-radius-lg: 0 !default;
$input-border-radius-lg: 0 !default;

$btn-padding-y-lg: 1rem !default;
$btn-padding-x-lg: 1.5rem !default;
$btn-font-size-lg: 1.4em !default;

/**
  * Transitions
 */
$transition-slow: 0.5s;
$transition-fast: 0.3s;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

/**
  * Fonts
 */
$custom-file-font-family: "Poppins", sans-serif;
$font-family-base: "Poppins", sans-serif;

$font-size-base: 1rem !default;
$font-size-lg: 1.625rem !default;
$font-size-md: 1.25rem !default;
$font-size-sm: 0.875rem !default;

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 2.75 !default;
$h3-font-size: $font-size-base * 2.25 !default;
$h4-font-size: $font-size-base * 2 !default;
$h5-font-size: $font-size-base * 1.5 !default;
$h6-font-size: $font-size-base * 1.31 !default;

$display1-size:               5rem !default;
$display2-size:               4rem !default;
$display3-size:               3.5rem !default;
$display4-size:               3rem !default;

/**
  * Colors
 */
$color-primary: #0D4C3D;
$color-secondary: #6E6E6E;
$color-white: #fff;
$color-lightgrey: #f4f5f5;

$theme-colors: (
        "primary": $color-primary,
        "secondary": $color-secondary,
        "info": teal,
        "cyan": #6ad1cf,
        "success": #C3F8CA,
        "dark": #32363C,
        "danger": tomato,
        "white" : $color-white,
        "lightgrey" : $color-lightgrey,
        "black": #000000,
        "black2": #040404,
        "green": #145c4b,
        "lightgreen": #caeaea,
        "blue": #225fa0,
        "blue2": #03274c,
        "blue3": #0d1527,
        "gray": #f4f5f5,
        "gray2": #979797,
        "gray3": #cecece,
);
// then in code: theme-color("black")

/**
  * Button
 */
$btn-link-disabled-color: $color-secondary !default;


// Links
//
// Style anchor elements.
$link-color:                              $color-primary !default;
$link-decoration:                         underline !default;
$link-hover-color:                        darken($link-color, 50%) !default;
$link-hover-decoration:                   none !default;

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 25% !default;

/**
  * Spacing
 */
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * 0.25),
                        2: ($spacer * 0.8125),
                        3: ($spacer * 1.5),
                        4: ($spacer * 1.875),
                        5: ($spacer * 3),
                        6: ($spacer * 2.125),
                        7: ($spacer * 2.5),
                        8: ($spacer * 3.75)
                ),
                $spacers
);


$card-spacer-y: $spacer * 1.5;

/**
  * Container Spacing
 */
// $section-margin-bottom: 5rem;

$breadcrumb-bg: theme-colors("white") !default;
$breadcrumb-padding-y: 0 !default;
$breadcrumb-padding-x: 0 !default;
$breadcrumb-margin-bottom: 1.5rem !default;

/**
  * Cards
 */
$card-columns-count:                4 !default;
$card-columns-gap:                  $spacer * 1.5 !default;
$card-border-radius:                0 !default;

/**
  * Forms
 */
$input-focus-width: .1rem !default;
$input-focus-color: $color-primary !default;
$input-focus-border-color: rgba($grey-x-light, .2) !default;
$input-focus-box-shadow: 0 0 0 $input-focus-width $input-focus-color !default;