.card-deck {
  margin-bottom: 2rem;
}

.card {
  border-radius: .375rem;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, .08);

  .card-body {
    .icon--network,
    .icon--sign_up,
    .icon--play,
    .icon--rocket {
      //padding: .75rem;
      //border-radius: .375rem;
      height: 50px;
      width: 50px;

      &.icon__green svg .cls-1 {
        fill: $green-light;
      }

      &.icon__orange svg .cls-1 {
        fill: $orange-light;
      }

      &.icon__red svg .cls-1 {
        fill: $red-light;
      }

      &.icon__yellow svg .cls-1 {
        fill: $yellow-light;
      }

      figure {
        margin: 0;
      }
    }
  }

  .card-footer {
    background: unset;
    border-top: unset;
    padding: 0 1.25rem 1.25rem;
  }

  &__checkIn svg {
    @include mr(2);
    @include mb(2);

    width: 24px;
    height: 24px;
  }
}


.week__card {
  .card {
    padding: .75rem 1.25rem 3rem;
  
    &.card--grey {
      background-color: $bg-grey;
      border-color: $border-grey;
    }

    &.card--green-x-dark {
      background-color: $green-x-dark;
      border: none;
      .card-body {
        .card-title {
          color: $white;
        }

        .card-text {
          color: $grey-x-light;
        }
      }
    }

    &.card--green-dark {
      background-color: $green-dark;
      border: none;
      .card-body {
        .card-title {
          color: $white;
        }

        .card-text {
          color: $grey-x-light;
        }
      }
    }

    // &.card--grey {
    //   background-color: $green-x-light;
    //   border: none;
    //   .card-body {
    //     .card-title {
    //       color: $white;
    //     }

    //     .card-text {
    //       color: $grey-x-light;
    //     }
    //   }
    // }

    .card-body {
      padding: 1.25rem;
      .card__label {
        .card-subtitle {
          span {
            background: $bg-red-x-light;
            border-radius: 2rem;
            color: $red;
            font-size: 1.5rem;
            line-height: 2.875rem;
            padding: 0.4rem 2rem;
          }
        }
      }
    }
  }
}

.card {
  &-deal {
    @extend .h-100, .border, .border-gray3;

    &:hover {
      @extend .shadow-lg;
    }

    cursor: pointer;

    &__label {
      @extend .small, .font-weight-bold, .mb-1, .d-block, .text-uppercase, .text-gray2;
    }

    &__badge {
      @extend .badge, .badge-lightgreen, .d-block, .border-0, .font-weight-normal, .px-2, .py-1;

      white-space: break-spaces;
      text-align: left;
    }

    &__category {
      @extend .mb-2;
    }
  }

  &-header {
    @extend .bg-white, .pb-0;
  }

  &-title {
    @extend .mb-0;

    font-size: $font-size-md;
  }

  &-text {
    font-size: $font-size-sm;
  }
}

.post_status .btn {
  @extend .mb-2;
}
