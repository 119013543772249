.post-item {
  @extend .d-block, .h-100, .position-relative;

  background: theme-color("lightgrey");

  &__content {
    @extend .px-4, .pt-4, .pb-8;
  }

  &__footer {
    @extend .px-4, .py-2, .border-top, .position-absolute, .w-100;

    bottom: 0;
    left: 0;

    span {
      @extend .text-muted;

      font-size: $font-size-sm;
    }
  }

  &__title {
    @extend .font-weight-normal;

    font-size: $h6-font-size;
    color: theme-color("muted")
  }

  &__image {
    height: 200px;
    margin: -2px;
  }

  &__link {
    @extend .d-block, .h-100, .position-relative;

    border: 2px solid theme-color("lightgrey");
    border-top: none !important;

    &:hover {
      @extend .text-decoration-none;

      .post-item__image {
        opacity: 0.8;
      }
    }
  }

  &__category {
    @extend .position-absolute, .px-3, .py-2, .small, .w-100;

    color: lighten(theme-color("white"), 50);
    z-index: 1;
    margin-left: -3px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 20%, #0000003b 100%);
  }
}