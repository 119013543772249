.academy-dash {
  background: lighten(theme-color("gray"),25);
  height: 100vh;

  .downIconOver {
    img {
      object-fit: cover;
    }
  }

  &__welcome {
    @extend .px-lg-8, .py-lg-4, .px-0, .py-0;

    &--title {
      @extend .font-weight-bolder;

      font-size: $h3-font-size;

      @include media-breakpoint-down(sm) {
        font-size: $h5-font-size;
      }

    }
  }

  &__content {
    min-height: calc(100vh - 180px);

    @extend .py-lg-4, .py-0;
  }
}
