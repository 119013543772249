.update-bar {
  @extend .text-dark;

  background: theme-color("white");
  position: fixed;
  bottom: 1em;
  left: 1em;
  padding: 1em;
  border-radius: 0.3em;
  box-shadow: 0 0.2em 0.8em 0 rgba(0, 0, 0, 0.3);
}

.update-bar-action {
  @extend .btn, .btn-primary, .ml-3;

  outline: none;
  cursor: pointer;
}
