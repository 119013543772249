.primaryNav {
  &-menu {
    @extend .list-unstyled, .m-0;
  }

  &-item {
    @extend .px-0, .px-md-2, .d-flex, .justify-content-center, .align-items-center;

    font-size: $h6-font-size;

    @include media-breakpoint-down(lg) {
      font-size: $font-size-base;
    }
  }
}


.mobileMenu-close {
  .mobile-area {
    .mobile-tabs {
      @extend .d-none;
    }
  }
}

.mobileMenu-open {
  .mobile-area {
    @extend .d-block, .w-100;

    overflow-y: scroll;
    max-height: 100%;

    .mobile-tabs {
      @extend .w-100;
    }
  }
}

.mobileMenu-close,
.mobileMenu-open {
  .mobile-area {
    @extend .d-block, .position-fixed;

    bottom: 0;
    right: 0;
    left: auto;
    top: auto;
    z-index: 1020;

    .mobileMenu-switch__button {
      @extend .float-right, .bg-lightgrey, .border, .text-center;

      border-top-left-radius: 8px;
      border-top-right-radius: 6px;
      outline: none;
      width: 80px;
      height: 80px;

      svg {
        @extend .mx-auto;
      }

      span {
        @extend .font-weight-bolder;
      }

      &:focus {
        @extend .bg-lightgrey;
      }
    }

    .mobile-tabs {
      @include make-row(0);

      @extend .bg-lightgrey;

      display: flex;

      &__link {
        @extend .col-6, .col-sm-4, .d-inline-block, .border;

        height: 100px;

        @include media-breakpoint-up(md) {
          height: 140px;
        }
      }

      &__item {
        @extend .d-flex, .align-items-center, .justify-content-center, .h-100, .flex-column, .text-center;

        svg {
          font-size: $font-size-lg;

          @extend .mb-2;
        }

        span {
          @extend .font-weight-bolder;
        }
      }
    }
  }
}

.mobileMenu-close {
  .mobile-menu {
    @extend .d-none;
  }
}
