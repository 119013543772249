.profile {
  position: relative;
  display: block;
  color: #000;

  h4 {
    @extend .mb-3;

    width: 100%;
    text-align: center;;
    color: theme-color("black");
  }

  &__content {
    //background: $color-lightgrey;
  }

  &__image {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.profileContent p {
  margin: 0;
}

.custom-input {
  height: auto;
  padding: 10px;
  border: 1px solid rgba(206, 212, 218, .6);
}

/*.login {*/
/*    width: 100%;*/
/*    max-width: 500px;*/
/*    margin: 150px auto 0;*/
/*}*/

.max-300 {
  max-width: 300px;
}

#root {
  padding-bottom: 30px;
}

.hidden {
  display: none
}

.label-file {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.label-file .downIconOver {
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all .2s ease-out;
  background-color: rgba(0, 0, 0, .2);
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.label-file .downIcon {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.label-file:hover .downIconOver {
  opacity: 1;
}

.StyleContainer {
  margin-bottom: 4%;
  margin-top: 4%;
}

.StylePlace {
  text-align: left;
}

.StyleDescription {
  text-align: left;
  border-left: 2.3px solid #17a2b8;
  padding-left: 10px;
}

.StyleName {
  font-size: 32px;
  font-weight: bold;
  text-align: left;
}