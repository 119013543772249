.comment-area {
  @extend .col-12, .col-md-8;

  form {
    @extend .mb-6;
  }
}

.modal {
  &-header {
    .close {
      border: none;
      background: none;
    }
  }
}
