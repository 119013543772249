.section__hero {
  &.hero--home {
    background: radial-gradient(#e7eaeb 37%, #e7eaeb);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-down(sm) {
      background-size: contain;
      height: auto;
    }

    .hero-startdate {
      @extend .bg-primary, .small, .border, .border-primary, .py-1, .px-2, .text-white;

      @include ml(2);

      position: relative;

      @include media-breakpoint-up(md) {
        position: absolute;
      }

      @include media-breakpoint-down(md) {
        display: block;

        @include mt(2);
        @include ml(0);
      }

      font-size: $font-size-base;

      &:before {
        content: "";
        border-style: solid;
        border-width: 5px 10px 5px 0;
        border-color: transparent theme-color("primary") transparent transparent;
        position: absolute;
        left: -10px;
        top: 10px;


        @include media-breakpoint-down(md) {
          border-width: 10px 15px 10px 0;
          left: 10%;
          top: -15px;
          transform: rotate(90deg);
        }
      }
    }

    @include media-breakpoint-down(xs) {
      min-height: 1300px;
    }

    .gatsby-image-wrapper {
      position: absolute;
      overflow: hidden;
      bottom: 0;
      max-width: 500px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      object-fit: contain;

      @include media-breakpoint-between(md, lg) {
        margin-right: 0;
      }
    }

    //h1 {
    //  font-size: $h4-font-size;
    //
    //  @include media-breakpoint-up(md) {
    //    font-size: $h3-font-size;
    //  }
    //}
  }

  .newsletter__form {
    select {
      background: #fff url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
      background-position: right 5px top; /* Change the px value here to change the distance */
      appearance: none;
      color: #188268;
    }

    select,
    input {
      @extend .mb-3, .mb-md-0;

      min-height: 55px;
      width: 100%;
    }

    .send-button {
      position: absolute;

      @include media-breakpoint-down(sm) {
        position: relative;
      }
    }

    //@include media-breakpoint-up(sm) {
    //  input {
    //    font-size: $font-size-lg;
    //  }
    //}
  }

  .hero__button {
    .btn {
      color: theme-color("success");
    }
  }
}
