.message {
    position: relative;
    padding: 10px 15px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
    transition: all .2s ease-out;
}

.message.active,
.message:hover {
    background-color: #f5f7fa;
}

.message.active::before {
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #019dde;
    z-index: 10;
    position: absolute;
}

.message__name {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.message__info {
    width: 100%;
    height: 40px;
    overflow: hidden;
    color: #505458;
    font-size: 13px;
}

.message__date {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    color: #939393;
    line-height: normal;
}

.message-wrap {
    background-color: #f7f9fa;
    height: 80vh;
    display: flex;
}

#root {
    padding-bottom: 0 !important;
}

.message-card {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    width: 100%;
    display: flex;
}

.message-card--aside {
    width: 35%;
}

.message-cards {
    display: flex;
    flex: 1 1 100%;
    padding-bottom: 20px;
}

.message-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 600px;
}

.message-aside__top,
.dialog-aside__top {
    padding: 15px;
    border-bottom: 1px solid #f1f1f1;
}

.message-aside,
.dialog-aside {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - 105px);
    max-height: 600px;

}

.message-aside-list {
    overflow-x: hidden;
    overflow-y: auto;
}

.message-notif {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767.98px) {
    .container {
        max-width: 100% !important;
    }

    .message-inner .message-card--aside {
        display: none;
    }

    .message-card:not(.message-card--aside) {
        display: flex;
        width: 100%;
        margin-left: 0 !important;
    }

    .message-inner.active .message-card--aside {
        width: 100%;
        display: flex;
    }

    .message-inner.active .message-card:not(.message-card--aside) {
        display: none;
    }
}