html {
  font-size: 100%;
  background: #0F2B24;

  &[data-theme="large"] {
    font-size: 125%;
  }

  body {
    min-height: 100vh;
  }

  @include media-breakpoint-down(sm) {
    font-size: 110%;

    &[data-theme="large"] {
      font-size: 115%;
    }
  }

  main.main-content:not(.home):not(.course):not(.about-us) {
    @include py(6);

    .page__title {
      font-size: $h4-font-size;

      @include mb(4);
    }
  }
}


.title, h1, h2, h3, h4, h5, h6 {
  color: $color-primary;
}

hr {
  border-top-color: $border-green;
  box-shadow: 0 1px 1px rgba(255, 255, 255, .5);
}

main section:not(.noticeBar) {
  .container,
  .container-fluid {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $green !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $green !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $green !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: $green !important;
}

section {

  &.section--grey {
    background-color: $bg-grey;
  }
  
  &.section--green {
    background-color: $bg-green;
    h1 {
      color: $white;
    }
    p {
      color: $green-light;
    }
  }

  &.section--green-light {
    background-color: $bg-green-light;
  }

  &.section--green-dark {
    background-color: $green-dark;
  }

  &.section--green-dark {
    background-color: $green-dark;

    .section__title {
      color: $white;
    }

    p {
      color: $green-light;

      b, strong {
        color: $white;
      }
    }

    hr {
      border-top-color: $green-x-dark;
      box-shadow: 0 1px 1px rgba(129, 129, 129, 0.5);
    }
  }

  &.title--alt-bottom {
    padding-bottom: 6rem;
  }

  .section__title {
    margin-bottom: 3rem;
  }

  &.week {
    &.week--gradient {
      background: #F6F7F9;
      background-image: radial-gradient(#ffffff 70%, transparent 65%);
      background-size: 150vw 140vw;
      background-position: center -5%;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        background-size: 175vw 120vw;
      }
    }
  }

}

img {
  &.has-shadow {
    box-shadow: 0 2rem 2rem rgba(0, 0, 0, .25);
    border-radius: 6px;
  }
}

/* Loader */
.loader {
  @extend .d-flex, .flex-column, .align-items-center, .justify-content-center, .position-fixed;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  opacity: 0.9;
  z-index: $zindex-modal;

  svg {
    @extend .mt-3;

    fill: #155b4b;
  }
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a {
  img {
    &.alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    &.alignnone {
      margin: 5px 20px 20px 0;
    }

    &.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    &.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

a[disabled] {
  pointer-events: none;
  opacity: .6;
}

a.bp-suggestions-mention {
  pointer-events: none;
}


.CookieConsent {
  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }

  div {
    font-size: $font-size-sm;
    flex: initial !important;
    line-height: $line-height-lg;
  }
}

.background-image {
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
