.week {
  .week__card {
    &.card--absolute {
      @include media-breakpoint-up(md) {
        position: absolute;
      }
    }
  }

  .week--three {
    .row {
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }
    }
  }

  .week--four {
    @include media-breakpoint-up(md) {
      padding-bottom: 14rem;
    }
  }

  .week--five {
    .week__card {
      z-index: 9;
      @include media-breakpoint-up(md) {
        margin-top: -12rem;
      }
    }
  }

  .week--seven {
    .row {
      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }
    }
  }

  .week__image {
    &.image--absolute {
      @include media-breakpoint-up(md) {
        position: absolute;
      }
    }

    img {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

  }
}